#contact-form {
    width: 60%;
    margin: auto;
    text-align: center;
    margin-top: 10%;
    color: white;
}

h1 {
    font-weight: 625;
}

.form-field {
    /*margin: auto;*/
    margin-bottom: 20px;
    width: 100%;
    font-size: large;
    

}

.form-field label {
    display: block;
    text-align: left;
    width: 100%;
    font-weight: 550;
    font-size: 1rem;
    
    margin-bottom: 1.5%;

}

.form-field input {
    width: 100%;
    /*min-height: 2.5vh;*/
    padding: 1.5%;
    font-size: large;
    border: none;
    background-color: lightgray;
}

.form-field textarea {
    width: 100%;
    height: 25vh;
    padding: 1.5%;
    font-size: large;
    background-color: lightgray;
    border: none;
}

button {
    cursor: pointer;
    border: none;
    font-weight: 600;
}

#subscribe {
    margin-bottom: 2rem;
    height: .75rem;
    width: .75rem;
}

#contact-form #submit-button {
    font-size: 1rem;
}

.comment-checkbox {
    display:flex;
    
}
.checkbox-label {
    /* color: var(--theme-secondary-color); */
    text-shadow: 2px 2px black;
    font-size: 1rem;
}


.submit-message {
    margin-top: 1%;
    margin-bottom: 1%;
}