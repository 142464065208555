.coming-soon-container {
    background-color: var(--theme-background-color);
    padding-top: 5%;
}

.coming-soon-book {
    width: 100%;
    margin-top: 0;
    opacity: 0;
    animation: coming-soon-content-anim 0s linear 1s 1 forwards;
    margin-right: 0px;

}

#coming-soon-title {
    text-align: center;
    font-size: 2.5rem;
    color: var(--theme-secondary-color);
    opacity: 0;
    animation: coming-soon-title-anim 0.5s linear 0.25s 1 forwards;
    padding-bottom: 2.5%;
    margin: 0;
}


.page-spacer {
    height: 35vh;
}

.home-about-content {
    background-color: var(--theme-background-color);
}


/* #other-books-title {
    text-align: center;
    font-size: 7.5vmin;
    color: var(--theme-highlight-color);
    margin-bottom: 2.5%;
} */

@keyframes coming-soon-title-anim {
    from {
        opacity: 0%;
        transform: translateY(25px);
    }
    to {
        transform: translateY(0);
        opacity: 100%;
    }
}

@keyframes coming-soon-content-anim {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}