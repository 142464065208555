.social-links {
    list-style-type: none;
    
}

.social-links.social-flex {
    
    display: flex;
    padding-left: 0;
    /* align-self: end; */
    /* width: 100%; */
    
}

.social-links .social-block {
    display: block;
    margin: 0;
    padding: 0;
}


@media (max-width:750px) {
    ul.social-links.social-flex {
        align-items: center;
        justify-content: center;
    }
}