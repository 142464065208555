.landing-page-title {
    color: white;
  font-size: 1.5rem;
  /* display: flex; */
  font-weight: 1;

  /* padding-bottom: 1vh; */
  margin: auto;
  margin-bottom: 1vh;
  margin-top: 5rem;
  /* fill: white; */
  text-shadow: 5px 3px #3d3d76;
  max-width: 75%;
  

}


.landing-page {
    text-align: center;
    justify-content: center;
}

.landing-page form {
    max-width: 50%;
    margin: auto;
    margin-top: 3rem;
}

.short-story-ad {
    margin-top: 5rem;
}

.landing-page #email-form .form-field #submit-button {
    font-size: 1.1rem;
}

.disclaimer-label {
    font-size: .75rem;
    color: white;
    text-shadow: 1px 1px black;
}