.individual-blog-contents {
    background-color: var(--theme-background-color);
    padding-bottom: 1rem;
}
.other-posts {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    align-items: flex-start;
}

.next-post, .next-post:visited, .next-post:focus {
    color: var(--theme-text-color);
}

.last-post, .last-post:visited, .last-post:focus {
    color: var(--theme-text-color);
}

.next-post {
    align-self: flex-end;
}