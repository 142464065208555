.book-layout {
    
    /* max-width: 85%; */
    padding-left: 7.5%;
    padding-right:7.5%;
    padding-bottom: 10px;
    padding-top: 3%;

    max-height:50%;
    /* background-color: transparent; */
    margin:auto;
    background-color: var(--theme-background-color);
    
    
}

.book-subtitle {
    /* margin: auto; */
    /* text-align: center; */
    font-size: 1.25rem;
    margin-bottom: 3vh;
    margin-top: 0;
    padding-left: 2vh;
}

.book-layout-contents {
    display: flex;
    align-items: center; 
    max-height: 100%;
}

.book-image {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-title {
    /* background-color: transparent; */
    font-size: 2rem;
    /* text-align: center; */
    /* text-shadow: 1px 1px black; */
    width: 100%;
    /* background-color: #ead985; */
    /* color: #0044b8; */
    margin-bottom: .5vh;
    padding-left: 2vh;
}

.book-text {
    max-width: 75%;
}

.blurb {
    font-size: 1rem;
    padding-left: 2vh;
    width: 90%;
    text-align: justify;
    
}


@media(max-width:750px) {

    .book-layout-contents {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        
    }

    .book-image {
        width:auto;
    }
    .blurb {
        display: none;
        width: 100%;
        text-align: left;
    }

    .blurb.no-vanish {
        display: block;
    }
    
}
