
.image-link {
    height: max-content;
    /* width: 75%; */
    display:flex;
    justify-content: center;
    align-items: center;
}

.shadowed {
    filter: drop-shadow(10px 10px 10px black);
}

.background {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: var(--theme-highlight-color);
    margin: auto;
    margin-top: 5px;
    transition: transform .2s;
    /* padding: 10%; */
}
.no-background {
    transition: transform .2s;
}


.image-link-image {
    max-height: 50vmin;
    max-width: 75%;
    
    
}

.background:hover {
    transform: scale(1.1);
}

.no-background:hover {
    transform: scale(1.1);
}

.no-link:hover {
    transform: scale(1);
}


.image-link-link {
    height: max-content;
    /* width: 75%; */
    display:flex;
    justify-content: center;
    align-items: center;
}

hr {
    margin-top: 5%;
}

@media (max-width:750px) {
    .image-link.no-background {
        max-height: 100%;
        max-width: 50%;
        display: flex;
        align-content: center;
    }
    .image-link-image {
        max-height: 100%;
    }
}

