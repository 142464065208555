.about-content-container {
    background-color: var(--theme-background-color);
    padding-bottom: 2%;
}

#author-image-container {
    width: 500px;
    height:500px;
}

#author-image {
    height:100%;
    width: 100%;
}
.author-bio-link, .author-bio-link:visited, .author-bio-link:focus {
    color: var(--theme-text-color);
    text-decoration: none;
}
.media-kit-container {
    display:block;
    margin-top: 2rem;
    margin-bottom: .5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.media-kit-description {
    font-size: .75rem;
    text-align: center;
    margin-right: 1rem;
}

