.App-footer {
    margin-top: 15%;
    width: 100%;
    height: max(100px, 15%);
    /* background-color: #3d3d76; */
    /* color: white; */
    background: radial-gradient(circle, rgba(27, 34, 57, 1) 50%, rgba(26, 35, 50, 1) 60%, rgba(12, 21, 30, 1) 100%);
    color: white;
}

.footer-columns {
    display: flex;
    flex-wrap: wrap!important;
    flex-direction: row;
    /* margin-left: 15%;
    margin-right: 15%; */
    margin: auto;
}

.footer-column {
    display: block;
    text-align: center;
    padding: min(2.5%, 15px);
    width: 25%;
    margin:auto;
    margin-top:1vh;
}

.column-title {
    font-size: medium;
    font-weight: 400;
    height: 15px;
}

.column-link-list {
    margin-top: min(5%, 50px);
}

.info-text-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 5%;
    margin-right: 5%;
    /* align-content: space-between; */
    /* width: 100%; */
    /* margin-top: 5%; */
    min-height: 5vh;
}

.social-icon-links {
    display: flex;
    margin:auto;
    width: 90%;
}

.social-icon-links > span {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 7.5%;
    padding-right: 7.5%;

}


.creator-text {
    /* padding-left: 10%; */
    position: absolute;
    right: 0%;
    bottom: 1%;
}

.copyright-notice {
    position: absolute;
    left: 0%;
    bottom: 1%;
    
}


#author-name-link, #author-name-link:visited, #author-name-link:focus {
    /* color: var(--theme-text-color); */
    color: white;
    text-decoration: none;

}

.column-break {
    display: none;
    height: 1px;
}
@media (max-width:750px) {
    .footer-column {
        flex-basis: 100%;
    }
    .info-text-group {
        text-align: center;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 2%;
        
    }
    .social-icon-links{
        margin-top: 5%;
        display: block;
    }
    .column-break {
        display: block;
        width: 100vw;
        position: absolute;
        left:0;
        margin-bottom: 1%;
    }
    .creator-text {
        flex-basis: 100%;
        position: static;
        
    }
    .copyright-notice {
        flex-basis: 100%;
        position: static;
        
    }
    
}