.nav-link {
    padding: 5px;
    text-transform: uppercase;
    text-decoration: none;
    /* font-size: medium; */
    transition-duration: 0.2s;
    margin: 0.5rem;
}


.light, .light:visited, .light:focus {
    color: white;
}
.dark, .dark:visited, .dark:focus {
    color: var(--theme-highlight-color);
    text-decoration: underline;
    font-size: small;
}

.dark:hover {
    color: rgba(145, 81, 4, 0.724)
    
}
.light:hover {
    text-decoration: underline;
    color: rgba(255, 145, 0, 0.648);
}
