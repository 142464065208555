
  
  /* .formField {
      margin-bottom:20px;
      text-align:center;
  }
  
  .formField select {
      height:45px;
      width:175px;
  } */

  button {
    cursor: pointer;
    border: none;
    font-weight: 600;
    color: var(--theme-button-text-color)
  }
  
  .btn {
    /* margin-top: 0.5rem; */
    display: inline-block;
    padding: 0.3rem 0.8rem;
    font-weight: 700;
    background-color: var(--theme-highlight-color);
    color: var(--theme-button-text-color);
    border-radius: 5px;
    text-align: center;
    font-size: 0.75em;
  }
  
 

  .submit-message {
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .success {
    color: rgb(12, 222, 12);
    text-shadow: 1px 1px black;
  }
  .failed {
    color: red;
    text-shadow: 1px 1px black;
  }

  #email-field {
    width: 75%;
  }