#chyron {
    max-width: 100%;
    height: 10rem;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: nowrap!important;
    font-size: 1.25rem;
    
}

.chyron-short-blurb {
    margin-bottom: 0;
    color: red;
    /* animation: coming-soon-title-anim 0s linear 0.25s 1 forwards, delay-anim 2.5s 1 linear forwards; */
}

.chyron-source {
    margin-top: 0.25rem;
    color: red;
    /* animation: coming-soon-title-anim 0s linear 0.25s 1 forwards, delay-anim 2.5s 1 linear forwards; */
}


@keyframes coming-soon-title-anim {
    from {
        opacity: 0%;
        transform: translateY(25px);
    }
    to {
        transform: translateY(0);
        opacity: 100%;
    }
}

@keyframes delay-anim {
    from {
        opacity: 100%;
    }
    to {
        opacity: 100%;
    }
}