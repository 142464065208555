.blog-layout-container {
    background-color: var(--theme-background-color);
}

.blog-layout {
    /* width: 100%; */
    margin: auto;
    padding-left: 12.5%;
    padding-right: 12.5%;
    margin-bottom: 2rem;
    padding-top: 1rem;
}

.blog-link, .blog-link:visited, .blog-link:focus {
    /* color: var(--theme-highlight-color); */
    color: var(--theme-secondary-color);
    text-decoration: none;
    
}
.blog-title-container {
    /* display: flex; */
    flex-wrap: wrap;
}
.date-title {
    align-self: center;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1rem;
    color: var(--theme-secondary-color);
}

.blog-title {
    color: var(--theme-secondary-color);
    font-size: 1.5rem;
    margin-right: 5%;
}

.blog-subtitle {
    color: var(--theme-text-color);
    font-size: 1.25rem;
}

.blog-content {
    color: var(--theme-text-color);
    font-size: 1rem;
}

@media(max-width:750px) {
    
}