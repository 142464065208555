.social-link {
    margin:auto;
    padding:.5vw;
    transform: scale(1.5);
}
.social-link-background:hover {
    transform: scale(1.1);
}

@media (max-width:750px) {
    .social-link {
        margin: 5px;
        transform: scale(1);
    }
}