.nav-links {
    list-style-type: none;
    background-color: transparent;
}

.nav-flex {
    padding-left: 0px;
    display: flex;

    /* align-self: start; */
    
}

.nav-block {
    display: block;
    margin: 0px;
    padding: 0px;
}

@media (max-width:750px) {
    ul.nav-links.nav-flex {
        align-items: center;
        justify-content: center;
    }
}