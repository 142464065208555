.individual-book-page-content {
    background-color: var(--theme-background-color);
    padding-bottom: 1rem;
}

button {
    cursor: pointer;
    border: none;
    font-weight: 600;
}

.link-to-buy-title {
    text-align: center;
}

.link-to-buy {
    display: flex;
    align-content: center;
    justify-content: center;
}

.link-btn {
    margin-top: 0.5rem;
    display: inline-block;
    padding: 0.4rem 0.8rem;
    font-weight: 700;
    background-color: var(--theme-highlight-color);
    color: var(--theme-button-text-color);
    border-radius: 5px;
    text-align: center;
    font-size: 0.75rem;


}

