.App-header {
  /* background-color: #282c34; */
  /* background-color: black; */
  min-height: 15vmin;
  /* position: absolute; */
  z-index: -1;

  /* justify-content: center; */
  /* font-size: calc(10px + 1vmax); */
  color: white;
  /* background-image: var(--header-background-image); */

  /* background-image: url('../../assets/images/headerBackground3-min.svg'); */
  /* background-image: url("https://scottayoungauthor.com/assets/headerBackground3.png"); */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: 'Archivo Black';

}

.App-header section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.subscribe {
  font-size: 1rem;
  /* margin-bottom: 1vh; */
}

.header-author-title {
  flex-direction: column;
  text-align: center;
  width: auto;
  margin: auto;
}

.author-name {
  /* font-family: 'Anton'; */
  color: white;
  font-size: 2rem;
  /* display: flex; */
  font-weight: 1;

  /* padding-bottom: 1vh; */
  margin-bottom: 1vh;
  margin-top: 0px;
  /* fill: white; */
  text-shadow: 5px 3px #3d3d76;

}

.author-subtitle {
  font-size: 2.5vmax;
  /* display: flex; */
  font-weight: 1;
  margin: 0;

  /* text-shadow: 5px 3px #3d3d76; */
}

.author-tagline {
  font-size: 1rem;
  /* display: flex; */
  text-align: center;
  font-weight: 1;
  margin: 1vh;
  text-shadow: 4px 2px #3d3d76;
}

.links {

  font-size: 1rem;
  width: 10%;
  margin: 2%;
}

.header-blog-update {
  font-size: 1.5rem;
  margin-right: 2%;
 
  color: var(--theme-highlight-color);
  /* background-color: black; */

  margin-top: 5rem;
  
  filter: drop-shadow(10px 10px 10px black);

}
.header-blog-update #email-form {
  width: 75%;
  margin: auto;
}

.header-blog-update img {
  max-width: 75%;
  margin: 5%;
}

.header-blog-update-text {
  text-align: center;
  width: 75%;
}

.modal {
  background: radial-gradient(circle, rgba(27, 34, 57, 1) 50%, rgba(26, 35, 50, 1) 60%, rgba(12, 21, 30, 1) 100%);
  position: fixed;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 2;
  min-width: 50vh;
  min-height:50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: x-large;
}

.close {
  position: absolute;
  top: 5%;
  left: 5%;
  font-size: 1.25rem;
  height: 25px;
  width: 25px;
  font-weight: 700;
  background-color: transparent;

  color: var(--theme-highlight-color);
  border-radius: 5px;
  text-align: center;
}



.header-left-links {

  text-align: left;
}

.header-right-links {
  justify-content: right;
  /* display: flex; */
  font-size: 1.1rem;
}


#header-spacer {
  width: 100%;
}

.header-hamburger {
  display: none;
}

.header-hamburger-menu p {
  font-size: 1.25rem;
}

.header-hamburger-inner-menu {
  position: fixed;
  top: 0px;
  z-index: 2;
  height: 60%;
  width: 100%;
  background: radial-gradient(circle, rgba(27, 34, 57, 1) 50%, rgba(26, 35, 50, 1) 60%, rgba(12, 21, 30, 1) 100%);
  /* background-color: black; */
  font-size: 1.25rem;
  padding-top: 2vh;
}

.modal-hidden {
  display: none;
}

@media (max-width:750px) {
  .App-header {
    min-height: 15vh;
  }

  #header-spacer {
    width: 0%;
    display: none;
  }




  .header-hamburger {
    top: 15px;
    left: 15px;
    display: block;
    /* background-color: rgba(12,21,30,1); */
    /* border-radius: 7px; */
    z-index: 3;
    
  }

  .header-hamburger.fixed {
    position: fixed;
    top: 15px;
    left: 15px;
  }

  .header-hamburger.absolute {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .header-author-title {
    padding-top: 8vh;
    padding-bottom: 1vh;

  }

  .links {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    /* margin: auto; */
    /* padding: auto; */
  }

  ul {
    padding-left: 0px;
  }
}