

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --theme-highlight-color: #CFB770;
  --theme-text-color: black;
  --theme-button-text-color: black;
  --theme-background-color: rgb(255, 238, 207);
  --theme-secondary-color: #DF0D3F;
  --header-background-image: url('https://scottayoungauthor.com/assets/headerBackground3-min.svg');
  --background-image: url('./assets/images/background.svg');
  font-size: 25px;
}

body:before {
  content: "";
  display:block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-image: var(--background-image);
  background-repeat: no-repeat;

  /* background-position: -150rem; */
  /* background-attachment: fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


body {
  color: var(--theme-text-color);
  font-family: 'Montserrat';
  font-weight: 500;

  /* background: rgb(37,42,58);
  background: radial-gradient(circle, rgba(37,42,58,1) 15%, rgba(26,35,50,1) 28%, rgba(12,21,30,1) 53%, rgba(89,73,99,1) 100%); */
  /* background: rgb(27,34,57); */
  /* background: radial-gradient(circle, rgba(27,34,57,1) 30%, rgba(26,35,50,1) 46%, rgba(12,21,30,1) 86%, rgba(89,73,99,1) 100%); */
  background-color: var(--theme-background-color);

  color: var(--theme-text-color);
  font-family: 'Montserrat';
  font-weight: 500;

}

.App {
  text-align: center;
  
}

h1, h2, h3 {
  letter-spacing: 2.5px;
  font-family: 'Archivo Black';
}

/* main {
  background-image: var(--background-image); */
  /* background-image: var(--header-background-image);
  background-position: -150rem;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
} */


@media(max-width:750px) {
  :root {
    font-size: 2vmax;
  }
}